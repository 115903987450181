/* organogram.css */
.my-label {
    border: 1px solid black;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    display: inline-block;
    margin: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.my-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.my-node {
    margin: 20px;
}

.my-line {
    border-left: 2px solid black;
    height: 20px;
    margin-left: 50%;
}

.my-line-border {
    border-top: 2px solid black;
}

.my-label {
    border: 2px solid #3A6ED4;
    /* Blue border */
    border-radius: 8px;
    display: inline-block;
    width: 150px;
    text-align: center;
    margin: 0px;

    align-items: center;
    justify-content: center;
    padding: 0;
}

.staff-name {
    background-color: #3A6ED4;
    /* Blue background */
    color: white;
    /* White text for contrast */
    padding: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 2px solid #3A6ED4;
    /* Optional: to separate staff from role */
}

.role-name {
    color: #333;
    width: 100%;
    /* Darker text color for role */
    padding: 10px;
    font-size: 14px;
}