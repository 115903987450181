.search-img{
    height: 38px !important;
    width: 38px !important;
    margin-left: -12px;
    border-radius: 50% !important;
    border: 3px solid #fff !important; 
}
.mgt-top{
    margin-top: 2% !important;
}
.mgt-bottom{
    margin-bottom: 4% !important;
}

.paddingingx{
    padding: 20px !important;
}