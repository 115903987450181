.wb-league-header{
     padding:10px !important;
     background-image: url('./background-animation.gif');
     background-color: rgba(58, 110, 212,0.1); 
     border-radius: 10px !important;
    }
     .avatar {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 1em;
      }
      
      .table{
        margin-top: 20px;
        border-radius: 0px !important;
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
             
      }
      .black-text{
          color:#000 !important;
      }
      
      .table thead{
        background:#000;
       /* background: linear-gradient(0deg, rgba(34,193,195,0.8) 0%, rgba(253,187,45,0.7) 100%);
      */  border-radius: 0px !important;
        color:#fff;
      }
      
      .table tr th,.table tr td:nth-child(odd){
      text-align:center;
      }
      table.striped>tbody>tr:nth-child(odd) {
      
     }
    .white-text{
        color: #fff !important;
    }
    .co-white{
        color:#fff !important;
    }