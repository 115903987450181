@import "~bootstrap/scss/bootstrap.scss";

:root {
  --primary-color: #3a6ed4;
  --lightgray: #e8e9eb;
  --gray: #8a9099;
  --darkgray: #656b74;
  --light-red: #fd7972;
  --green: #5abc7a;
  --yellow: #fc9f13;
  --pink: #ff5964;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 10pt;
}

body {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background: #fcfcfc;
}

a,
a:active,
a:visited,
a:focus,
a:link {
  color: inherit;
  text-decoration: none;
}

button {
  outline: none !important;
}

.sub-menu-container {
  @include media-breakpoint-up(md) {
    width: 25%;
  }
}

.main-content {
  width: 75%;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.form-container {
  width: 50%;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.auth {
  height: 100vh;
  width: 100vw;
  position: fixed;

  &-form {
    border-radius: 0.875rem;
    width: 80%;
    background: #fff;
    padding: 5rem 6rem 0 6rem;
    box-shadow: 0px 0px 5px rgb(233, 233, 233);
    @include media-breakpoint-down(md) {
      margin-right: 0;
      padding: 2rem;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .demarcator {
      color: #8a9099;
      position: relative;
      text-transform: uppercase;
      font-size: 0.75rem;

      &::before {
        content: "";
        position: absolute;
        width: 30%;
        left: 1%;
        top: 50%;
        border-bottom: 1px solid #ccc;
      }

      &::after {
        content: "";
        position: absolute;
        width: 30%;
        right: 1%;
        top: 50%;
        border-bottom: 1px solid #ccc;
      }
    }
    a {
      color: #3a6ed4 !important;
    }
  }
}

.search-container {
  animation: slideIn 0.5s linear forwards;
  @keyframes slideIn {
    from {
      transform: translateX(10px);
    }
    to {
      transform: translateX(0px);
    }
  }
}

.row {
  min-height: 500px;
}

.fw-bold {
  font-weight: 600;
}

.MuiDivider-root {
  background-color: #e8e9eb !important;
}

.MuiOutlinedInput-input {
  padding: 15px 14px !important;
}

.MuiFilledInput-underline::before {
  border-bottom: transparent !important;
}

.borderless {
  border-color: transparent !important;
}

.mid-radius {
  border-radius: 1rem;
}

.bottom-mid-radius {
  border-radius: 0 0 1rem 1rem;
}

.MuiOutlinedInput-adornedEnd {
  background: #f6f4f4;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.MuiInput-underline {
  border: 1px solid #e8e9eb;
  border-radius: 0.875rem;
  padding: 0.2rem 1rem;
  &::before {
    border-color: transparent !important;
  }
  &::after {
    border-color: transparent !important;
  }
}

h3 {
  color: #3f434a;
}

ul li {
  list-style: none;
}

.cursor-pointer {
  cursor: pointer;
}

.w-40 {
  width: 40%;
}

.small-text {
  font-size: 0.75rem;
}

.large-text {
  font-size: 1.3rem;
}

.medium-text {
  font-size: 0.875rem !important;
}

.bigger-text {
  font-size: 1.1rem !important;
}

.bolder-text {
  font-weight: 600 !important;
}

.thin-text {
  font-weight: 300;
}

.co-primary {
  color: var(--primary-color) !important;
}

.co-lightred {
  color: var(--light-red) !important;
}

.co-gray {
  color: var(--gray);
}

.co-green {
  color: var(--green);
}

.co-darkgray {
  color: var(--darkgray);
}

.co-white {
  color: #fff;
}

.bg-white {
  background: white;
}

.bg-darkgrey {
  background: #7d899b;
}

.bg-green {
  background: var(--green);
}

.bg-pink {
  background: #e8323e;
}

.bg-gray {
  background: #f7f7f7;
}

.co-red {
  color: #e24329;
}

.bg-primary {
  background: var(--primary-color) !important;
}

.w-85 {
  width: 85%;
}

.border-primary {
  border: 2px solid var(--primary-color);
  border-radius: 5px;
}

.transparent-border {
  border-color: transparent;
}

.section-header {
  border-bottom: 1px solid var(--lightgray);

  p {
    text-transform: uppercase;
    border-bottom: 2px solid var(--primary-color);
    width: fit-content;
  }
}

.sticky {
  z-index: 100;
}

.bg-lightred {
  background: var(--light-red);
}

button:disabled {
  cursor: not-allowed;
}

.doc-link {
  color: #fff !important;
  text-align: center;
}

.empty-state {
  margin-top: 7rem;
}

.copy-link {
  resize: none;
  outline: none;
  &:focus {
    outline: none;
  }
}
.min-height-25 {
  min-height: 25rem;
}

.min-width-2 {
  min-width: 2.5rem;
}

@media(max-width: 768px) {
  .biz-logo {
    display: none !important;
  }
  .auth-form-wrapper {
    width: 100% !important;
  }
}