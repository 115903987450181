.finerx{
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    padding: 10px;
    margin:30%;
    margin-top:0% !important;
    margin-bottom: 0% !important;
    color:#fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 15px;
}

.co-primary-x{
 color:#fff;

}

.finerx-second{
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    padding: 10px;
      margin-top:0% !important;
    margin-bottom: 0% !important;
    margin:27%;
    color:#fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 15px;

}

.finerx-third{
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    padding: 10px;
    margin:24%;
    color:#fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 15px;

}

.finerx-fourth{
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    padding: 10px;
    margin:20%;
    color:#fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 15px;

}


.finerx-fifth{
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    padding: 10px;
    margin:18%;
    color:#fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 15px;

}


.finerx-sixth{
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    padding: 10px;
    margin:15%;
    color:#fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 15px;

}


.finerx-seventh{
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    padding: 10px;
    margin:13%;
    color:#fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 15px;

}


.finerx-eight{
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    padding: 10px;
    margin:10%;
    color:#fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 15px;

}


.finerx-nineth{
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    padding: 10px;
    margin:8%;
    color:#fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 15px;

}