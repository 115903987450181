.table-title-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.table-title-area h3 {
    font-size: 24px;
    font-weight: 600;
    color: #1F2230;
    line-height: 1.7;
}

.table-wrapper {
    width: 100%;
    overflow-x: auto;
    margin: 0 auto;
    margin-bottom: 30px;
    box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    border-radius: 8px;
}

.eg-table {
    width: 100%;
    border-collapse: collapse;
}

.eg-table thead {
    background: #F1F1F1;
}

.eg-table thead tr th {
    font-size: 1rem;
    font-weight: 600;
    color: #1F2230;

    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #eaeaea;
    white-space: nowrap;
}

.eg-table tbody tr td {
    font-weight: 400;
    color: black;
    text-align: center;

    padding: 15px 20px;

    border-bottom: 1px solid #f1f1f1;
    vertical-align: middle;
    font-family: "Inter", sans-serif;
}

.eg-table td,
.eg-table th {
    padding: 25px 20px;

    text-align: left;
}

.no-data-message {
    font-weight: bold;
    text-align: center;
    color: #090892;
    padding: 15px;
}

.table-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.table-pagination p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #1F2230;
}

.pagination-wrap {
    display: flex;
    gap: 10px;
}

.pagination {
    list-style: none;
    display: flex;
    gap: 5px;
    padding: 0;
    margin: 0;
}

.dropdown-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;

}

.custom-select {
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 0.2px 0.2px rgba(0, 0, 0, 0.08);

    transition: all 0.3s ease;
    appearance: none;

    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path fill="%23000" d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-size: 14px 14px;
}

/* .custom-select:focus {
    border-color: #3A6ED4;

    outline: none;
    box-shadow: 0 0 6px rgba(65, 184, 131, 0.4);

} */

.custom-select option {
    padding: 10px;
    background-color: #fff;
}

.custom-select:hover {
    border-color: white;
}

.page-item {
    display: inline-block;
}

.page-link {
    padding: 10px 15px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    color: #696969;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.page-link:hover {
    background-color: #f1f1f1;
}

@media (max-width: 767px) {



    .eg-table tbody tr {

        margin-bottom: 30px;
        border: 1px solid #ddd;
        border-radius: 5px;
    }




    .eg-table tbody tr td::before {
        content: attr(data-label);
        font-weight: 600;
        color: #1F2230;
        flex: 1;
    }

    .custom-select,
    .btn {
        width: 100%;
        margin-top: 10px;

    }


    .table-pagination p {
        font-size: 14px;
    }

    .page-link {
        padding: 8px 12px;
    }


    .dropdown-wrapper {
        width: 100%;
    }
}