.btnc{
color:#fff;
margin-top:2.5%;
background-color:#3A6ED4;
margin-bottom: 2.5%;
border: solid 1px #3A6ED4;
padding: 5px;
}

.centralised-text{
 
    text-align: center;
    background-color: #fff;
    align-self: center;
    margin-top: 30% !important;
}

.maxh{
    height: 500px;
}
.maxh2{
    height: 800px; 
}
.no-height{
    height: 20;
}

 .likers-group {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
}

 .likers-group img {
    height: 38px !important;
    width: 38px !important;
    margin-left: -12px;
    border-radius: 50% !important;
    border: 3px solid #fff !important; 
}

.likers-text > p > span{
 color:#7a7a7a;
}

.md-dd{
    height: 38px !important;
    width: 38px !important;
}

.img-circle{
    border-radius: 100px;
}
.fontbold{
     color:#000 !important;
     font-weight: bold;
}
.name-1{
 color:cornflowerblue !important;
 font-weight: bold;
}

.name-2{
    color:cornflowerblue !important;
    font-weight: bold;

}
.mgtop{
    margin-top: 5%;
}

.panel-body:not(.two-col) { padding:0px !important; }
.glyphicon { margin-right:5px !important;; } 
.glyphicon-new-window { margin-left:5px !important;; }
.panel-body .radio,.panel-body .checkbox {margin-top: 0px !important; margin-bottom: 0px !important;}
.panel-body .list-group {margin-bottom: 0 !important;}
.margin-bottom-none { margin-bottom: 0 !important; }
.panel-body .radio label,.panel-body .checkbox label { display:block !important; }
.panel-primary{
    width: 600px !important;
}