.search-img{
    height: 38px !important;
    width: 38px !important;
    margin-left: -12px;
    border-radius: 50% !important;
    border: 3px solid #fff !important; 
}

.image-preview{
    height: 100px !important;
}
.table-bordered-padding{
    padding: 10px !important;
}
tr{
    height: 50px !important;
}

.img_class{
    height: 50px !important;

}