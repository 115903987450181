.filters {
    display: flex;
    justify-content: space-between;
}

.filters button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
}

.filters button.active {
    background-color: black;
    /* Change this to your desired background color */
    color: white;
    /* Change this to your desired text color */
    transform: translateY(-3px);
    /* Adjust this value as needed for the forward movement */
}

/* CSS to style the scrollbar */
.container {
    width: 100%;

    /* Set a fixed height and overflow-y to enable scrolling */
    height: 50%;
    /* Adjust height as needed */
    /* overflow-x: auto; */
    overflow-x: hidden;
    /* Enable vertical scrollbar */

    /* Styles for the scrollbar */
    scrollbar-width: "30px";
    /* "auto" or "thin" for Firefox */
    border-radius: 10px;
    /* For webkit-based browsers */
    scrollbar-color: #3a6ed4 transparent;
    /* scrollbar color */
}

/* Track */
.container::-webkit-scrollbar-track {
    background-color: transparent;
    /* color of the scrollbar track */
}

/* Handle */
.container::-webkit-scrollbar-thumb {
    background-color: #3a6ed4;
    /* color of the scrollbar handle */
    border-radius: 30px !important;
    /* rounded corners */
}

/* Handle on hover */
.container::-webkit-scrollbar-thumb:hover {
    background-color: #3a6ed4;
    /* color of the scrollbar handle on hover */
}