.border-list-link{
    border: solid 1px #ccc !important;
    padding: 5px !important;
    border-radius: 20px !important;
    cursor: pointer;
}
.border-list-link:hover{
 
    color:#fff !important;
}
.icon-img{
    height: 30px !important;
    width: 30px !important;
}


/*Business Card Css */
.business-card {
    border: 1px solid #cccccc;
    background: #f8f8f8;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .profile-img {
    height: 120px;
    background: white;
  }
  .job {
    color: #666666;
    font-size: 12px;
  }
  .mail {
    font-size: 13px;
   }
   .media-heading{
       font-size: 11px;
   }
 
   .profile-img{
    height: 30px !important;
    width: 30px !important;  
   }
   .media{
       margin-right: 5%;
   }