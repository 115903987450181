.btnc{
color:#fff;
margin-top:2.5%;
background-color:#3A6ED4;
margin-bottom: 2.5%;
border: solid 1px #3A6ED4;
padding: 5px;
}

.centralised-text{
 
    text-align: center;
    background-color: #fff;
    align-self: center;
    margin-top: 30% !important;
}

.width-100-white{
    color:#fff !important;
}
.maxh{
    height: 500px;
}
.maxh2{
    height: 800px; 
}

 .likers-group {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
}

 .likers-group img {
    height: 38px !important;
    width: 38px !important;
    margin-left: -12px;
    border-radius: 50% !important;
    border: 3px solid #fff !important; 
}

.likers-text > p > span{
 color:#7a7a7a;
}

.md-dd{
    height: 38px !important;
    width: 38px !important;
}

.img-circle{
    border-radius: 100px;
}
.fontbold{
     color:#000 !important;
}
.name-1{
 color:cornflowerblue !important;
 font-weight: bold;
}

.name-2{
    color:cornflowerblue !important;
    font-weight: bold;

}
.mgtop{
    margin-top: 5%;
}

.thankyoubg{
    background:url(cardbg.jpeg) !important;
    background-size: cover !important;
}
.large-font{
    font-size: 18px !important;
    font-weight: bold !important;
}
.white-txt{
    color:#fff !important;
}

.content-width{
    width: 60% !important;
}
.font-b{
     font-size: 15px;
}