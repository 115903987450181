.co-darkgray{
    color:#000 !important;
}

.cursor-pointer{
    cursor: pointer;

}

.cursor-pointer:hover{
    border: solid 1px #007bff34;
}
.back-arrow{
    height: 50px;
}

h2{
    color:#7a7a7a;
}