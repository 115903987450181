button.active {
    background-color: black !important;
    border: none;
    color: white;
}

.select-container {
    position: relative;
    width: 100%;

}

.card-height {
    width: 100%;
    background-color: white;
    border: none;
    padding: 15px;
    min-height: 150vh;

    border-radius: 10px;
    overflow: auto;

    box-sizing: border-box;

}

.select-contain {
    padding: 7px;
    width: 100%;
    border: "1px solid #e8e9eb";

    outline: none;

}


.select-container select {
    width: 100%;
    padding: 7px;
    color: black !important;
    font-size: 13px;
    border: "1px solid #e8e9eb";
    border-radius: 5px;
    appearance: none;

    -webkit-appearance: none;

}


.select-container::after {
    content: '\25BC';

    position: absolute;
    top: 68%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}